import logo from './logo.svg';
import './App.css';
import Game from './Game';
import Conference from './Conference';

function App() {

  // Use URLSearchParams to parse the query string
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token'); // Replace 'myParam' with the key of the query string

  return (
    <div className="App">
      <div className="container">
        <div className="component-one">
          <Conference token={token} />
        </div>
        <div className="component-two">
          <Game />
        </div>
      </div>
    </div>
  );
}

export default App;

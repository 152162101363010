import React, { useEffect, useState, useRef } from 'react';
import { io } from 'socket.io-client';

const Game = () => {
  const [timeLeft, setTimeLeft] = useState(0);
  const [userName, setUserName] = useState(''); // User input for name
  const [userSwap, setUserSwap] = useState(''); // User input for swap
  const [isUserNameSubmitted, setIsUserNameSubmitted] = useState(false); // Track if the username was submitted
  const [turnOrder, setTurnOrder] = useState([]);
  const [nextTurn, setNextTurn] = useState(null); // State to store the next player
  const [currentTurn, setCurrentTurn] = useState(null);
  const [turnTimeLeft, setTurnTimeLeft] = useState(0); // 30-second timer for each turn
  const [inGame, setInGame] = useState(false);
  const [question, setQuestion] = useState(null); // State to store the question
  const [hasHadTurn, setHasHadTurn] = useState(false); // Track if the user has had a turn
  const [guesses, setGuesses] = useState({}); // Track the user's guesses

  const socketRef = useRef(null); // Hold the WebSocket connection reference

  // Initialize WebSocket connection when the component mounts
  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io('https://5ad0-45-134-20-150.ngrok-free.app'); // Connect to the server
    }

    const socket = socketRef.current;

    // Listen for the game start event
    socket.on('startGame', ({ turnOrder, userName, swapUser, timeLeft }) => {
      setTurnOrder(turnOrder); // Store the swapped names of the players
      setTimeLeft(timeLeft);
      setCurrentTurn(turnOrder[0]); // Start with the first player
      setUserSwap(swapUser)
      setUserName(userName)
    });

      // Listen for countdown updates
      socket.on('countdown', (newTimeLeft) => {
        setTimeLeft(newTimeLeft);
      });

    // Listen for turn updates (including the next player)
    socket.on('turnUpdate', ({ currentTurn, nextTurn, turnDuration }) => {
      setCurrentTurn(currentTurn);
      setNextTurn(nextTurn);
      setTurnTimeLeft(turnDuration);
      // console.log(turnDuration)
      // If it's the user's turn, reset hasHadTurn to false
      if (userName === currentTurn) {
        setHasHadTurn(false);
      }
    });

    // Listen for the question assigned to the current player
    socket.on('questionAssigned', (question) => {
      console.log(question)
      setQuestion(question); // Store the assigned question
    });

    // Handle join game confirmation
    socket.on('joinedGame', (success) => {
      if (success) {
        setInGame(true);
      } else {
        alert('Game is full or already started!');
      }
    });

    // Listen for endGame event
    socket.on('endGame', () => {
      alert('Game over!');
    });

    // Clean up the socket listeners when component unmounts
    return () => {
      socket.off('startGame');
      socket.off('turnUpdate');
      socket.off('questionAssigned');
      socket.off('joinedGame');
      socket.off('endGame');
    };
  }, [userName]);

  const submitUserName = () => {
    if (userName.trim() !== '') {
      socketRef.current.emit('joinGame', userName); // Send the chosen name to the server
      setIsUserNameSubmitted(true); // Disable the input once the name is submitted
    } else {
      alert('Please enter a valid username.');
    }
  };

  const endTurn = () => {
    socketRef.current.emit('endTurn'); // Emit the event to end the turn
    setHasHadTurn(true); // Mark that the user has taken their turn
  };

  const handleGuessChange = (player, event) => {
    // Update the guesses state when the user selects a guess for a player
    const newGuesses = { ...guesses, [player]: event.target.value };
    setGuesses(newGuesses);
  };

  const submitGuesses = () => {
    // Send the guesses to the server
    socketRef.current.emit('submitGuesses', guesses);
    alert('Guesses submitted!');
  };

  const isMyTurn = userName === currentTurn;

  // Filter out the user themselves from the guessable players
  //const guessablePlayers = turnOrder.filter(player => player !== userName);
  const guessablePlayers = turnOrder;

  return (
    <div>
      {!isUserNameSubmitted ? (
        <div>
          <h3>Enter Your Name:</h3>
          <input
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            placeholder="Enter your username"
          />
          <button onClick={submitUserName}>Submit</button>
        </div>
      ) : (
        <>
          <h3>You are : {userName} playing as {userSwap ? userSwap : '?'}</h3>
          <p>Time left: {timeLeft}s</p>
          <p>Turn Time left: {turnTimeLeft}s</p>
          <h3>Current go: {currentTurn}</h3>
          <h3>Next go: {nextTurn}</h3> 

          {/* Display the question only if it's the player's turn and a question is assigned */}
          {isMyTurn && question && (
            <div>
              <h3>Your Question:</h3>
              <p>{question.data}</p> {/* Display the question text */}
            </div>
          )}

          {inGame && (
            <button onClick={endTurn} disabled={!isMyTurn}>
              {isMyTurn ? 'End Turn' : 'Wait for your turn'}
            </button>
          )}

          {/* Guessing UI */}
          {inGame && (
            <div>
              <h3>Make Your Guesses</h3>
              <ul>
                {guessablePlayers.map((player, index) => (
                  <li key={index}>
                    <label>
                      {player} is playing as  
                      <select onChange={(event) => handleGuessChange(player, event)}>
                        <option value="">Select a player</option>
                        {turnOrder
                          .map((guessablePlayer, idx) => (
                            <option key={idx} value={guessablePlayer}>
                              {guessablePlayer}
                            </option>
                          ))}
                      </select>
                    </label>
                  </li>
                ))}
              </ul>

              {/* Submit guesses */}
              <button onClick={submitGuesses} disabled={Object.keys(guesses).length <= 3}>
                Submit Guesses
              </button>
            </div>
          )}

          {/* Show a message if the user has already had a turn */}
          {hasHadTurn && (
            <p>You have now had a go</p>
          )}
          
        </>
      )}
    </div>
  );
};

export default Game;
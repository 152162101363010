import {
    ControlBar,
    GridLayout,
    LiveKitRoom,
    ParticipantTile,
    RoomAudioRenderer,
    useTracks,
  } from "@livekit/components-react";
  import "@livekit/components-styles";
  import { Track } from "livekit-client";
  
  const serverUrls = 'wss://swapbody-8nyc9idu.livekit.cloud';
  const token = 'eyJhbGciOiJIUzI1NiJ9.eyJ2aWRlbyI6eyJyb29tSm9pbiI6dHJ1ZSwicm9vbSI6Im15LWZpcnN0LXJvb20ifSwiaXNzIjoiQVBJcThVODV6MzVmZ1BhIiwiZXhwIjoxNzI1ODYzNDk4LCJuYmYiOjAsInN1YiI6Impvbm9kb2dnIn0.IX3ScGYjxncNvhYlSUSt76oFmUyR74JVuwCVkuoP2G4';
  
  export default function Conference(props) {
    return (
      <LiveKitRoom
        video={true}
        audio={true}
        token={props.token}
        serverUrl={serverUrls}
        // Use the default LiveKit theme for nice styles.
        data-lk-theme="default"
       
      >
        {/* Your custom component with basic video conferencing functionality. */}
        <MyVideoConference />
        {/* The RoomAudioRenderer takes care of room-wide audio for you. */}
        <RoomAudioRenderer />
        {/* Controls for the user to start/stop audio, video, and screen
        share tracks and to leave the room. */}
        <ControlBar />
      </LiveKitRoom>
    );
  }
  
  function MyVideoConference() {
    // `useTracks` returns all camera and screen share tracks. If a user
    // joins without a published camera track, a placeholder track is returned.
    const tracks = useTracks(
      [
        { source: Track.Source.Camera, withPlaceholder: true },
        { source: Track.Source.ScreenShare, withPlaceholder: false },
      ],
      { onlySubscribed: false },
    );
    return (
      <GridLayout tracks={tracks} style={{ height: 'calc(100vh - var(--lk-control-bar-height))' }}>
        {/* The GridLayout accepts zero or one child. The child is used
        as a template to render all passed in tracks. */}
        <ParticipantTile />
      </GridLayout>
    );
  }